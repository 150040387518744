import loadable from "@loadable/component";
import React from "react";
import { string } from "prop-types";

const FeatureIcon = ({ icon }) => {
	const Icon = loadable(() =>
		import(`../Icons/elements/${icon}`).catch(() => import("./noIcon"))
	);
	return <Icon />;
};

FeatureIcon.propTypes = {
	icon: string,
};

export default FeatureIcon;
