import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";
import { number, string, arrayOf, shape, object } from "prop-types";
import React from "react";
import { Parallax } from "react-scroll-parallax";

import { KeyFeaturePropTypes, ImageArrayPropTypes } from "~/types";
import FeatureIcon from "Components/FeatureIcon";
import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import { richTextOptions } from "Services/helper-utils/rich-text";
import BackgroundCircle from "./elements/BackgroundCircle";
import FeatureImageScaler from "./elements/FeatureImageScaler";
import Styles from "./styles.css";

const KeyFeature = (props) => {
	const {
		background,
		title,
		index,
		featureType,
		featureImages,
		body,
		icon,
		images,
	} = props;
	const isEven = index % 2 === 0;
	return (
		<MinPageHeightWrapper style={Styles.wrapper(isEven)}>
			{index === 2 && <BackgroundCircle />}
			<div css={Styles.copy(isEven)}>
				<div css={Styles.icon}>
					<FeatureIcon icon={icon} />
				</div>
				<div css={Styles.type}>{featureType}</div>
				<h4>{title}</h4>
				<div css={Styles.body}>
					{documentToReactComponents(body.json, richTextOptions)}
				</div>
			</div>
			<div css={Styles.images(isEven)}>
				<FeatureImageScaler isEven={isEven}>
					{background && <div css={Styles.background(background)} />}
					{featureImages.map((image, image_index) => {
						const foundImage = images.find(
							(imageNode) => imageNode.node.name === image.id
						);
						if (!foundImage) {
							return null;
						}
						const parallax = image.parallax || [0, 0];
						return (
							<Parallax
								key={`${image.id}-${image_index}`}
								styleInner={Styles.imageInner(parallax[0])}
								styleOuter={Styles.imageOuter}
								y={parallax}>
								<div css={Styles.image(foundImage.node, image.style)} />
							</Parallax>
						);
					})}
				</FeatureImageScaler>
			</div>
		</MinPageHeightWrapper>
	);
};

KeyFeature.propTypes = {
	index: number,
	images: ImageArrayPropTypes,
	count: number,
	icon: string,
	background: string,
	featureImages: arrayOf(
		shape({
			id: string,
			parallax: arrayOf(number),
			style: object,
		})
	),
	...KeyFeaturePropTypes,
};

export default KeyFeature;

export const query = graphql`
	fragment KeyFeatureQuery on ContentfulProductKeyFeature {
		title
		slug
		featureType
		strap
		body {
			json
		}
		# testimonial {
		# 	...TestimonialQuery
		# }
	}
`;
