import styling from "Services/styling";

export default {
	wrapper: {
		width: "100%",
		minWidth: 250,
		maxWidth: 280,
		textAlign: "center",
		fontSize: "2.4rem",
		lineHeight: "2.8rem",
		fontWeight: 700,
		...styling.mixins.margin(0, 20, 40),
	},
	icon: {
		height: 30,
		...styling.mixins.margin(0, 0, 35),
		"> svg": {
			height: 30,
		},
	},
	type: {
		fontSize: "1.6rem",
		lineHeight: "1.6rem",
		textTransform: "uppercase",
		...styling.mixins.margin(0, 0, 20),
	},
};
