import { number, oneOfType, string, object } from "prop-types";
import React from "react";

import { ImagePropTypes } from "~/types";
import Image from "Components/Image";
import { EMPTY_OBJ } from "Services/constants/empty";
import styling from "Services/styling";

const circularStyle = (size, x, y, zIndex, style) => ({
	height: size,
	width: size,
	position: "absolute",
	top: "50%",
	left: "50%",
	zIndex,
	...styling.utils.maxRounded,
	...styling.mixins.translate(`${x}%`, `${y}%`),
	...styling.mixins.transitionMulti(["transform"], ["height"], ["width"]),
	...style,
});

const CircularImage = (props) => {
	const { fluid, offsetX, offsetY, size, style, title, zIndex } = props;
	return (
		<Image
			fluid={fluid}
			style={circularStyle(size, offsetX, offsetY, zIndex, style)}
			title={title}
		/>
	);
};

CircularImage.propTypes = {
	...ImagePropTypes,
	offsetX: number,
	offsetY: number,
	size: oneOfType([number, string]),
	style: object,
	zIndex: number,
};

CircularImage.defaultProps = {
	offsetX: -50,
	offsetY: -50,
	size: 500,
	style: EMPTY_OBJ,
	title: "",
	zIndex: -1,
};

export default CircularImage;
