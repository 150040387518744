import React from "react";

import mapBGImage from "~/images/map-bg.png";
import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";

const backgroundCircleStyle = (size) => ({
	height: size,
	width: `${size}px !important`,
	zIndex: -1,
	position: "absolute",
	top: 0,
	left: 0,
	backgroundColor: styling.colors.backgroundCircle,
	...styling.mixins.translate("-12%", "0"),
	...styling.utils.maxRounded,
	":after": {
		content: '""',
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		opacity: 0.2,
		backgroundImage: `url(${mapBGImage})`,
		backgroundPosition: "center center",
		backgroundRepeat: "repeat",
		backgroundSize: "900px 900px",
	},
});

const BackgroundCircle = ({ clientHeight, clientWidth }) => {
	const isLandscape = clientWidth >= clientHeight;
	let size = clientWidth * 2;
	if (!isLandscape) {
		size = clientHeight * 2;
	}
	return <div css={backgroundCircleStyle(size)} />;
};

export default withDisplayAware("clientHeight", "clientWidth")(
	BackgroundCircle
);
