import React from "react";
import { arrayOf, exact, string } from "prop-types";

import { KeyFeaturePropTypes, ImageArrayPropTypes } from "~/types";
import styling from "Services/styling";
import KeyFeature from "./elements/KeyFeature";
import KeyFeatureSummary from "./elements/KeyFeatureSummary";
import Styles from "./styles.css";

const keyFeatures = {
	home: {
		"ski-school-management-made-simpler": {
			icon: "circles",
			featureImages: [
				{
					id: "one_stop_shop",
					style: {
						top: 204,
						boxShadow: "none",
					},
				},
			],
		},
		"real-time-data-driven-decision-making": {
			icon: "graphs",
			background: "dot_grid",
			featureImages: [
				{
					id: "resort_average",
					parallax: [0, -10],
					style: {
						right: 83,
						top: 140,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "class_ratio",
					parallax: [10, -15],
					style: {
						right: -28,
						top: 219,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "nps",
					parallax: [20, -20],
					style: {
						right: 306,
						top: 441,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "guest_retention_funnel",
					parallax: [30, -25],
					style: {
						right: -28,
						top: 460,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"mountain-view": {
			icon: "maps",
			background: "arrow_grid",
			featureImages: [
				{
					id: "tag_image",
					parallax: [0, -10],
					style: {
						left: 0,
						top: 270,
						...styling.utils.maxRounded,
					},
				},
				{
					id: "desktopb",
					parallax: [10, -15],
					style: {
						left: 0,
						top: 62,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "profile",
					parallax: [20, -20],
					style: {
						left: 445,
						top: 187,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"paperless-class-management": {
			icon: "shadow-shadow",
			featureImages: [
				{
					id: "digital_class_list",
					parallax: [0, -10],
					style: {
						right: 62,
						top: 174,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "vip",
					parallax: [10, -15],
					style: {
						right: 30,
						top: 80,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "information",
					parallax: [20, -20],
					style: {
						right: 132,
						top: 462,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	"data-dashboards": {
		"get-an-actionable-overview-of-your-schools-performance": {
			icon: "graph-check",
			featureImages: [
				{
					id: "resort_average",
					parallax: [0, -10],
					style: {
						right: 111,
						top: 138,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "class_ratio",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 218,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "nps",
					parallax: [20, -20],
					style: {
						right: 336,
						top: 441,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "guest_retention_funnel",
					parallax: [30, -25],
					style: {
						right: 0,
						top: 460,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"track-guest-net-promoter-score-down-to-individual-instructors-and-products": {
			icon: "thumb-graph",
			featureImages: [
				{
					id: "instructor_breakdown",
					parallax: [0, -10],
					style: {
						left: 22,
						top: 125,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "class_type",
					parallax: [10, -15],
					style: {
						left: 258,
						top: 442,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"know-exactly-which-instructors-and-products-drive-guest-retention": {
			icon: "refresh-graph",
			featureImages: [
				{
					id: "retention_rates",
					parallax: [0, -10],
					style: {
						right: 111,
						top: 80,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "retention_rates_2",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 206,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "retention_factors",
					parallax: [20, -20],
					style: {
						right: 68,
						top: 427,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"benchmark-your-schools-performance-against-your-peers": {
			icon: "trophy-graph",
			featureImages: [
				{
					id: "corporate_dashboards",
					parallax: [0, -10],
					style: {
						left: 10,
						top: 232,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "total_lessons",
					parallax: [10, -15],
					style: {
						left: 343,
						top: 200,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "hover",
					parallax: [10, -15],
					style: {
						left: 505,
						top: 240,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	"class-management": {
		"hello-digital-class-lists-goodbye-paper-chaos": {
			icon: "document-cloud",
			featureImages: [
				{
					id: "digital_class_list",
					parallax: [0, -10],
					style: {
						right: 62,
						top: 174,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "vip",
					parallax: [10, -15],
					style: {
						right: 30,
						top: 80,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "information",
					parallax: [20, -20],
					style: {
						right: 132,
						top: 462,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"data-entry-made-easy-with-handheld-scanners": {
			icon: "scanner-clipboard",
			featureImages: [
				{
					id: "scanner_image",
					parallax: [0, -10],
					style: {
						left: 17,
						top: 90,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "class_list_info",
					parallax: [10, -15],
					style: {
						left: 120,
						top: 365,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"smarter-school-data-built-on-accurate-class-lists": {
			icon: "lightbulb-clipboard",
			featureImages: [
				{
					id: "instructor_breakdown",
					parallax: [0, -10],
					style: {
						right: 103,
						top: 185,
						...styling.mixins.borderRadius(8),
					},
				},
				{
					id: "instructor_breakdown_2",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 281,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "class_ratio",
					parallax: [20, -20],
					style: {
						right: 143,
						top: 75,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "guest_retention_funnel",
					parallax: [30, -25],
					style: {
						right: 206,
						top: 481,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	"mountain-view": {
		"real-time-tracking-watch-your-kids": {
			icon: "clock-radar",
			featureImages: [
				{
					id: "map_guests",
					parallax: [0, -10],
					style: {
						right: 103,
						top: 233,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "profile_2",
					parallax: [10, -15],
					style: {
						right: 295,
						top: 207,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_2",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 355,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_2",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 487,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_1",
					parallax: [10, -15],
					style: {
						right: 100,
						top: 530,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_1",
					parallax: [10, -15],
					style: {
						right: 397,
						top: 167,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"real-time-instructor-management": {
			icon: "clock-shadows",
			featureImages: [
				{
					id: "desktopb",
					parallax: [0, -10],
					style: {
						left: 44,
						top: 206,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "profile",
					parallax: [20, -15],
					style: {
						left: 132,
						top: 333,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_inst",
					parallax: [30, -20],
					style: {
						left: 540,
						top: 161,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_guest",
					parallax: [30, -20],
					style: {
						left: 465,
						top: 179,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "transfer_guest",
					parallax: [30, -20],
					style: {
						left: 533,
						top: 224,
						boxShadow: "none",
					},
				},
			],
		},
		"transparent-conversations-with-parents": {
			icon: "messages",
			featureImages: [
				{
					id: "desktopa",
					parallax: [0, -10],
					style: {
						right: 103,
						top: 200,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "mobile_2",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 142,
						...styling.mixins.transformOrigin("100%", "0"),
						...styling.mixins.scale(0.58),
						...styling.mixins.borderRadius(40),
					},
				},
			],
		},
	},
	"relive-your-lesson": {
		"post-lesson-guest-engagement": {
			icon: "replay-map",
			featureImages: [
				{
					id: "map",
					parallax: [0, -10],
					style: {
						right: 0,
						top: 30,
						...styling.mixins.transformOrigin("100%", "0"),
						...styling.mixins.scale(0.82),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "email",
					parallax: [10, -15],
					style: {
						right: 295,
						top: 93,
						...styling.mixins.transformOrigin("100%", "0"),
						...styling.mixins.scale(0.82),
						...styling.mixins.borderRadius(40),
					},
				},
			],
		},
		"review-and-remember-old-lessons": {
			icon: "replay-shadows",
			featureImages: [
				{
					id: "map",
					parallax: [0, -10],
					style: {
						left: 17,
						top: 24,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.82),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "lesson_history",
					parallax: [10, -15],
					style: {
						left: 317,
						top: 84,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.82),
						...styling.mixins.borderRadius(40),
					},
				},
			],
		},
		"same-day-guest-survey-feedback": {
			icon: "stars",
			featureImages: [
				{
					id: "prompt",
					parallax: [0, -10],
					style: {
						right: 0,
						top: 114,
						...styling.mixins.transformOrigin("100%", "0"),
						...styling.mixins.scale(0.64),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "take_the_survey",
					parallax: [10, -15],
					style: {
						right: 393,
						top: 114,
						...styling.mixins.transformOrigin("100%", "0"),
						...styling.mixins.scale(0.64),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "survey",
					parallax: [20, -20],
					style: {
						right: 160,
						top: 39,
						...styling.mixins.transformOrigin("100%", "0"),
						...styling.mixins.scale(0.82),
						...styling.mixins.borderRadius(40),
					},
				},
			],
		},
	},
	evaluations: {
		"tailor-your-evaluation-process": {
			icon: "process-gear",
			featureImages: [
				{
					id: "create_new_process",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 175,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "mid_season",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 119,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "start_of_season",
					parallax: [20, -20],
					style: {
						right: 200,
						top: 439,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"data-driven-evaluation-metrics-and-supervisor-notes": {
			icon: "shadow-graph",
			featureImages: [
				{
					id: "nps",
					parallax: [0, -10],
					style: {
						left: 19,
						top: 255,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "top_instructors",
					parallax: [10, -15],
					style: {
						left: 271,
						top: 30,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "nps_per_class",
					parallax: [20, -20],
					style: {
						left: 188,
						top: 583,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"draft-finalize-publish-at-your-pace": {
			icon: "document-check",
			featureImages: [
				{
					id: "manage_hover",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 236,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "menu",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 164,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"director-level-progress-reports-and-peer-review": {
			icon: "shadows-graph",
			featureImages: [
				{
					id: "my_staff",
					parallax: [0, -10],
					style: {
						left: 10,
						top: 202,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "overview",
					parallax: [10, -15],
					style: {
						left: 452,
						top: 99,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "supervisor_card",
					parallax: [20, -20],
					style: {
						left: 396,
						top: 436,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	"staff-management": {
		"know-and-manage-your-staff-better": {
			icon: "face-shadows",
			featureImages: [
				{
					id: "list_of_profilesa",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 124,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "profile",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 84,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "total_training",
					parallax: [20, -20],
					style: {
						right: 220,
						top: 426,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"a-performance-tab-based-on-real-time-guest-feedback": {
			icon: "clock-mortar",
			featureImages: [
				{
					id: "kids_lesson_breakdown",
					parallax: [0, -10],
					style: {
						left: 18,
						top: 118,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "adults_lesson_breakdown",
					parallax: [10, -15],
					style: {
						left: 96,
						top: 363,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "breakdown_highlight",
					parallax: [20, -20],
					style: {
						left: 247,
						top: 297,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"who-are-my-top-and-bottom-performing-staff": {
			icon: "shadows-graph",
			featureImages: [
				{
					id: "list_of_profilesb",
					parallax: [0, -10],
					style: {
						right: 104,
						top: 174,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "average_nps",
					parallax: [10, -15],
					style: {
						right: 146,
						top: 423,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_1",
					parallax: [20, -20],
					style: {
						right: 147,
						top: 96,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "profile_2",
					parallax: [30, -25],
					style: {
						right: 0,
						top: 264,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	training: {
		"simple-clinic-creation-and-registration": {
			icon: "clipboard-pencil",
			featureImages: [
				{
					id: "calendar",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 201,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "date_creation",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 81,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "training_card_hover",
					parallax: [0, -10],
					style: {
						right: 302,
						top: 506,
						...styling.mixins.borderRadius(4),
						":before": {
							content: '""',
							position: "absolute",
							height: 0,
							width: 0,
							top: 18,
							left: -18,
							...styling.mixins.border(
								[12, "solid", styling.colors.transparent],
								[9, "solid", "#CED6E0"],
								[12, "solid", styling.colors.transparent],
								[9, "solid", styling.colors.transparent]
							),
						},
						":after": {
							content: '""',
							position: "absolute",
							height: 0,
							width: 0,
							top: 18,
							left: -17,
							...styling.mixins.border(
								[12, "solid", styling.colors.transparent],
								[9, "solid", "#FFF"],
								[12, "solid", styling.colors.transparent],
								[9, "solid", styling.colors.transparent]
							),
						},
					},
				},
			],
		},
		"take-clinic-attendance-and-get-feedback": {
			icon: "calendar-message",
			featureImages: [
				{
					id: "clinic_profile",
					parallax: [0, -10],
					style: {
						left: 18,
						top: 148,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.64),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "review_clinic",
					parallax: [10, -15],
					style: {
						left: 410,
						top: 148,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.64),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "take_attendance",
					parallax: [20, -20],
					style: {
						left: 194,
						top: 110,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.74),
						...styling.mixins.borderRadius(40),
					},
				},
			],
		},
		"analyse-clinic-popularity-with-real-time-data": {
			icon: "zoom-graph",
			featureImages: [
				{
					id: "saturation_report",
					parallax: [0, -10],
					style: {
						right: 52,
						top: 148,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "category",
					parallax: [10, -15],
					style: {
						right: 450,
						top: 405,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "day_of_week",
					parallax: [20, -20],
					style: {
						right: 225,
						top: 448,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "trainers",
					parallax: [30, -25],
					style: {
						right: 0,
						top: 483,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	hiring: {
		"keep-track-of-your-best-candidates-between-seasons": {
			icon: "crosshair",
			featureImages: [
				{
					id: "talent_pool_list",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 179,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "talent_pool_returning",
					parallax: [10, -15],
					style: {
						right: 80,
						top: 422,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "talent_pool_recommendation",
					parallax: [20, -20],
					style: {
						right: 0,
						top: 147,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"create-custom-processes-for-each-type-of-job": {
			icon: "process-diagram",
			featureImages: [
				{
					id: "job_details",
					parallax: [0, -10],
					style: {
						left: 19,
						top: 176,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "email_template",
					parallax: [10, -15],
					style: {
						left: 338,
						top: 111,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "send_when_ready",
					parallax: [20, -20],
					style: {
						left: 188,
						top: 506,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"automate-mailout-and-reminders": {
			icon: "envelope-alarm",
			featureImages: [
				{
					id: "mailout_list",
					parallax: [0, -10],
					style: {
						right: 111,
						top: 165,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "reminders",
					parallax: [10, -15],
					style: {
						right: 200,
						top: 492,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "mailout_profile",
					parallax: [20, -20],
					style: {
						right: 0,
						top: 105,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	scheduling: {
		"schedule-the-seasons-shifts-in-seconds": {
			icon: "timer-circle",
			featureImages: [
				{
					id: "shifts_bg",
					parallax: [0, -10],
					style: {
						left: 18,
						top: 240,
						boxShadow: "none",
					},
				},
				{
					id: "shifts",
					parallax: [10, -15],
					style: {
						right: 44,
						top: 195,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "contextual_panel",
					parallax: [20, -20],
					style: {
						right: 0,
						top: 420,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"optimise-daily-assignments": {
			icon: "mixer",
			featureImages: [
				{
					id: "tasks_bg",
					parallax: [0, -10],
					style: {
						left: 87,
						top: 81,
						boxShadow: "none",
					},
				},
				{
					id: "tasks",
					parallax: [0, -10],
					style: {
						left: 18,
						top: 182,
						...styling.mixins.borderRadius(16),
					},
				},
			],
		},
		"full-point-of-sale-integration": {
			icon: "shop-arrows",
			featureImages: [
				{
					id: "pos_flaik",
					parallax: [0, -10],
					style: {
						right: 0,
						top: 152,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "pos",
					parallax: [10, -15],
					style: {
						right: 320,
						top: 216,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "arrows",
					parallax: [10, -15],
					style: {
						right: 280,
						top: 311,
						boxShadow: "none",
					},
				},
			],
		},
		"live-updates-to-instructors": {
			icon: "bell-bell",
			featureImages: [
				{
					id: "timeline",
					parallax: [0, -10],
					style: {
						left: 21,
						top: 56,
						boxShadow: "none",
					},
				},
				{
					id: "mobile",
					parallax: [10, -15],
					style: {
						left: 386,
						top: 121,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "drag",
					parallax: [10, -15],
					style: {
						left: 47,
						top: 558,
						boxShadow: "none",
					},
				},
			],
		},
	},
	availability: {
		"set-up-custom-needs-and-rules": {
			icon: "right-turn-gear",
			featureImages: [
				{
					id: "calendar",
					parallax: [0, -10],
					style: {
						right: 111,
						top: 179,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "limits",
					parallax: [10, -15],
					style: {
						right: 69,
						top: 467,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "calendar_key",
					parallax: [20, -20],
					style: {
						right: 0,
						top: 107,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"automated-mailout-and-reminders": {
			icon: "envelope-alarm",
			featureImages: [
				{
					id: "mailout",
					parallax: [0, -10],
					style: {
						left: 18,
						top: 102,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "email_timing",
					parallax: [10, -15],
					style: {
						left: 157,
						top: 354,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "dropdown",
					parallax: [10, -15],
					style: {
						left: 487,
						top: 572,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
		"get-real-time-responses": {
			icon: "clock-spanner",
			featureImages: [
				{
					id: "reponses_table",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 114,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "sent_+_viewed",
					parallax: [10, -15],
					style: {
						right: 105,
						top: 422,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "contact",
					parallax: [20, -20],
					style: {
						right: 0,
						top: 208,
						...styling.mixins.borderRadius(4),
					},
				},
			],
		},
	},
	"instructor-experience": {
		"for-instructors-on-the-snow-go": {
			icon: "calendar-message",
			featureImages: [
				{
					id: "clinic_profile",
					parallax: [0, -10],
					style: {
						left: 18,
						top: 148,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.64),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "review_clinic",
					parallax: [10, -15],
					style: {
						left: 410,
						top: 148,
						...styling.mixins.transformOrigin("0", "0"),
						...styling.mixins.scale(0.64),
						...styling.mixins.borderRadius(40),
					},
				},
				{
					id: "mobile",
					parallax: [20, -20],
					style: {
						left: 194,
						top: 110,
						...styling.mixins.borderRadius(32),
					},
				},
			],
		},
		"real-time-schedule-updates": {
			icon: "bell-bell",
			featureImages: [
				{
					id: "timeline",
					parallax: [0, -10],
					style: {
						left: 21,
						top: 56,
						boxShadow: "none",
					},
				},
				{
					id: "mobile",
					parallax: [10, -15],
					style: {
						left: 386,
						top: 121,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "drag",
					parallax: [10, -15],
					style: {
						left: 47,
						top: 558,
						boxShadow: "none",
					},
				},
			],
		},
		"register-for-training-clinics": {
			icon: "clipboard-pencil",
			featureImages: [
				{
					id: "calendar",
					parallax: [0, -10],
					style: {
						right: 112,
						top: 201,
						...styling.mixins.borderRadius(16),
					},
				},
				{
					id: "date_creation",
					parallax: [10, -15],
					style: {
						right: 0,
						top: 81,
						...styling.mixins.borderRadius(4),
					},
				},
				{
					id: "training_card_hover",
					parallax: [0, -10],
					style: {
						right: 302,
						top: 506,
						...styling.mixins.borderRadius(4),
						":before": {
							content: '""',
							position: "absolute",
							height: 0,
							width: 0,
							top: 18,
							left: -18,
							...styling.mixins.border(
								[12, "solid", styling.colors.transparent],
								[9, "solid", "#CED6E0"],
								[12, "solid", styling.colors.transparent],
								[9, "solid", styling.colors.transparent]
							),
						},
						":after": {
							content: '""',
							position: "absolute",
							height: 0,
							width: 0,
							top: 18,
							left: -17,
							...styling.mixins.border(
								[12, "solid", styling.colors.transparent],
								[9, "solid", "#FFF"],
								[12, "solid", styling.colors.transparent],
								[9, "solid", styling.colors.transparent]
							),
						},
					},
				},
			],
		},
	},
};

const KeyFeatures = (props) => {
	const { images, features, pageSlug } = props;
	const isHome = pageSlug === "home";
	return (
		<>
			{!isHome && (
				<div css={Styles.summaryFeatures}>
					{features.map((feature, index) => {
						const keyFeatureDetails =
							(keyFeatures[pageSlug] &&
								keyFeatures[pageSlug][feature.slug] &&
								keyFeatures[pageSlug][feature.slug]) ||
							null;
						if (!keyFeatureDetails) {
							return null;
						}
						return (
							<KeyFeatureSummary
								key={feature.slug}
								{...keyFeatureDetails}
								{...feature}
							/>
						);
					})}
				</div>
			)}
			{features.map((feature, index) => {
				const keyFeatureDetails =
					(keyFeatures[pageSlug] &&
						keyFeatures[pageSlug][feature.slug] &&
						keyFeatures[pageSlug][feature.slug]) ||
					null;
				if (!keyFeatureDetails) {
					return null;
				}
				return (
					<KeyFeature
						key={feature.slug}
						index={index + (isHome ? 0 : 1)}
						count={features.length}
						images={images}
						{...keyFeatureDetails}
						{...feature}
					/>
				);
			})}
		</>
	);
};

KeyFeatures.propTypes = {
	images: ImageArrayPropTypes,
	features: arrayOf(exact(KeyFeaturePropTypes)),
	pageSlug: string,
};

export default KeyFeatures;
