import arrowGridImage from "~/images/arrow_grid.png";
import dotGridImage from "~/images/dot_grid.png";
import terrainMapImage from "~/images/terrain_map.png";
import { EMPTY_ARR, EMPTY_OBJ } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	wrapper: (isEven) => ({
		":before": {
			content: '""',
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			...styling.mixins.border(
				[1, "dashed", styling.colors.textDefault],
				EMPTY_ARR,
				EMPTY_ARR
			),
		},
		[styling.sizes.breakpoints.medium]: {
			justifyContent: isEven ? "flex-end" : "flex-start",
			flexDirection: "row",
			":before": {
				content: "unset",
			},
		},
	}),
	copy: (isEven) => ({
		position: "relative",
		zIndex: 1,
		flexShrink: 0,
		h4: {
			fontSize: "4rem",
			lineHeight: "4rem",
			...styling.mixins.margin(0, 0, 20),
		},
		[styling.sizes.breakpoints.medium]: {
			width: "360px !important",
			order: isEven ? 2 : 1,
		},
	}),
	icon: {
		height: 30,
		...styling.mixins.margin(0, 0, 40),
		"> svg": {
			height: 30,
		},
	},
	type: {
		fontSize: "1.6rem",
		lineHeight: "1.6rem",
		fontWeight: 700,
		textTransform: "uppercase",
		...styling.mixins.margin(0, 0, 20),
	},
	body: {
		color: styling.colors.textQuadrinary,
		fontSize: "2rem",
		lineHeight: "2.8rem",
		strong: {
			color: styling.colors.textTertiary,
		},
	},
	images: (isEven) => ({
		width: "100% !important",
		[styling.sizes.breakpoints.medium]: {
			order: isEven ? 1 : 2,
			width: "auto",
			flexGrow: 1,
		},
	}),
	imageOuter: {
		position: "absolute",
		height: "100%",
		width: "100%",
		top: 0,
		left: 0,
	},
	imageInner: (initialY) => ({
		position: "absolute",
		height: "100%",
		width: "100%",
		top: 0,
		left: 0,
		...styling.mixins.translate(0, `${initialY}%`),
		...styling.mixins.transition("transform"),
	}),
	image: (
		{
			childImageSharp: {
				original: { src, height, width },
			},
		},
		style
	) => ({
		position: "absolute",
		height: height / 2,
		width: width / 2,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center, center",
		backgroundSize: `${width / 2}px ${height / 2}px`,
		backgroundImage: `url(${src})`,
		...styling.mixins.boxShadow(10, 10, 40, 0, styling.colors.boxShadow),
		...style,
	}),
	background: (backgroundType) => {
		switch (backgroundType) {
			case "dot_grid": {
				return {
					position: "absolute",
					top: "-25%",
					left: "-25%",
					width: "150%",
					height: "150%",
					backgroundPosition: "center center",
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundImage: `url(${dotGridImage})`,
				};
			}
			case "arrow_grid": {
				return {
					":before": {
						content: '""',
						position: "absolute",
						width: "125%",
						right: 0,
						top: "-12%",
						height: "125%",
						backgroundPosition: "center center",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundImage: `url(${terrainMapImage})`,
					},
					":after": {
						content: '""',
						position: "absolute",
						width: "85%",
						left: 0,
						bottom: "-5%",
						height: "85%",
						backgroundPosition: "left bottom",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundImage: `url(${arrowGridImage})`,
					},
				};
			}
			default:
				return EMPTY_OBJ;
		}
	},
};
