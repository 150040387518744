import React from "react";
import { bool, node, number } from "prop-types";

import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";

const width = 720;

const scalerStyle = (scale, isEven) => ({
	position: "relative",
	height: 410,
	width,
	...styling.mixins.margin(
		0,
		scale === 1 ? "auto" : 0,
		0,
		isEven || scale === 1 ? "auto" : -80
	),
	...styling.mixins.scale(scale),
	...styling.mixins.transformOrigin(0, 0),
	[styling.sizes.breakpoints.small]: {
		height: 615,
	},
	[styling.sizes.breakpoints.medium]: {
		height: 820,
		...styling.mixins.margin(0, "auto"),
	},
});

const FeatureImageScaler = ({ children, clientWidth, isEven }) => {
	let scale = 1;
	if (clientWidth < width) {
		scale = clientWidth / width;
	}
	return <div css={scalerStyle(scale, isEven)}>{children}</div>;
};

FeatureImageScaler.propTypes = {
	children: node,
	clientWidth: number,
	isEven: bool,
};

export default withDisplayAware("clientWidth")(FeatureImageScaler);
