import styling from "Services/styling";

export default {
	icon: {
		height: 50,
		width: "50px !important",
		...styling.mixins.margin(0, 0, 15),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.margin(0, 0, 35),
		},
	},
	buttonContainer: {
		textAlign: "center",
		maxWidth: 220,
		...styling.mixins.margin(0, "auto"),
		...styling.mixins.padding(30, 0, 0),
	},
};
