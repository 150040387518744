import Img from "gatsby-image";
import { object } from "prop-types";
import React from "react";

import { ImagePropTypes } from "~/types";
import { EMPTY_OBJ } from "Services/constants/empty";

const Image = ({ fluid, title, imgStyle, style }) =>
	fluid && <Img alt={title} fluid={fluid} imgStyle={imgStyle} style={style} />;

Image.propTypes = {
	...ImagePropTypes,
	imgStyle: object,
	style: object,
};

Image.defaultProps = {
	fluid: null,
	imgStyle: EMPTY_OBJ,
	style: EMPTY_OBJ,
	title: "",
};

export default Image;
