import React from "react";

import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";

const circleStyle = (size) => ({
	height: size * 7,
	width: size * 7,
	position: "absolute",
	left: -40,
	backgroundColor: styling.colors.backgroundCircle,
	zIndex: -3,
	...styling.utils.maxRounded,
	[styling.sizes.breakpoints.medium]: {
		left: -70,
		height: size * 4.5,
		width: size * 4.5,
	},
	[styling.sizes.breakpoints.large]: {
		height: size * 2,
		width: size * 2,
	},
});

const BackgroundCircle = ({ clientWidth }) => (
	<div css={circleStyle(clientWidth)} />
);

export default withDisplayAware("clientWidth")(BackgroundCircle);
