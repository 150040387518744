import { graphql } from "gatsby";
import { bool, string } from "prop-types";
import React from "react";

import { TestimonialPropTypes } from "~/types";
import CircularImage from "Components/CircularImage";
import { Comment } from "Components/Icons";
import Image from "Components/Image";
import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";
import Styles from "./styles.css";

const testimonialImageProps = {
	small: {
		offsetX: 0,
		offsetY: 0,
		size: 375,
		style: {
			position: "relative",
			top: 0,
			left: 0,
			...styling.mixins.margin(0, "auto", 30),
		},
	},
	medium: {
		offsetX: 0,
		offsetY: 0,
		size: 375,
		style: {
			position: "relative",
			top: 0,
			left: 0,
			...styling.mixins.margin(0, "auto", 30),
		},
	},
	large: {
		offsetX: -87,
		size: 900,
		style: {
			...styling.mixins.margin(0, "auto", 30),
		},
	},
	xlarge: {
		offsetX: -117,
		size: 900,
		style: {
			...styling.mixins.margin(0, "auto", 30),
		},
	},
};

const rippleCount = 5;

const Testimonial = (props) => {
	const { avatar, body, fullName, isFullBlock, position, resort, size } = props;
	if (isFullBlock) {
		return (
			<MinPageHeightWrapper>
				{avatar && (
					<div css={Styles.avatarWrapper(testimonialImageProps[size].size)}>
						<CircularImage
							{...testimonialImageProps[size]}
							fluid={avatar.fluid}
							title={fullName}
							zIndex={2}
						/>
						<div
							css={Styles.avatarRipple(
								testimonialImageProps[size].size,
								testimonialImageProps[size].offsetX
							)}>
							{[...Array(rippleCount)].map((nothing, index) => (
								<span
									key={index}
									css={Styles.ripple(
										index,
										rippleCount - 1,
										testimonialImageProps[size].size
									)}
								/>
							))}
						</div>
					</div>
				)}
				<div css={Styles.textWrapper}>
					<div css={Styles.icon}>
						<Comment />
					</div>
					<p css={Styles.title}>{resort.title}</p>
					<p css={Styles.body}>“{body}”</p>
					<p css={Styles.byLine}>
						<span css={Styles.fullName}>
							{fullName}
							{position && ", "}
						</span>
						{position && <span>{position}</span>}
					</p>
					<Image
						fluid={resort.logo && resort.logo.fluid}
						style={Styles.resortImage}
						title={fullName}
					/>
				</div>
			</MinPageHeightWrapper>
		);
	}
	return <div />;
};

Testimonial.defaultProps = {
	isFullBlock: false,
};

Testimonial.propTypes = {
	isFullBlock: bool,
	size: string,
	...TestimonialPropTypes,
};

export default withDisplayAware("size")(Testimonial);

export const query = graphql`
	fragment TestimonialQuery on ContentfulTestimonial {
		fullName
		avatar {
			fluid(maxWidth: 900) {
				sizes
				src
				srcSet
			}
			title
		}
		position
		body
		resort {
			title
			logo {
				fluid(maxWidth: 100) {
					sizes
					src
					srcSet
				}
				title
			}
		}
	}
`;
