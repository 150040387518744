import styling from "Services/styling";

export default {
	avatarWrapper: (size) => ({
		position: "relative",
		zIndex: -1,
		[styling.sizes.breakpoints.medium]: {
			position: "unset",
		},
	}),
	avatarRipple: (size, offsetX) => ({
		height: size,
		width: size,
		position: "absolute",
		left: "50%",
		top: "50%",
		zIndex: 1,
		...styling.mixins.translate("-50%", "-50%"),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.translate(`${offsetX}%`, "-50%"),
		},
	}),
	ripple: (index, count, size) => ({
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%",
		opacity: 0.6 - (0.4 / count) * index,
		...styling.utils.maxRounded,
		...styling.mixins.border([1, "solid", styling.colors.ripple]),
		// increase each ring by a third of the initial size
		...styling.mixins.scale(((size / 3) * (index + 1) + size) / size),
	}),
	textWrapper: {
		width: "100%",
		[styling.sizes.breakpoints.medium]: {
			width: "50%",
			...styling.mixins.margin(0, 0, 0, "50%"),
		},
	},
	icon: {
		height: 50,
		width: 50,
		...styling.mixins.margin(0, 0, 40),
	},
	title: {
		fontSize: "1.6rem",
		lineHeight: "1.6rem",
		textTransform: "uppercase",
		fontWeight: 700,
		color: styling.colors.textSecondary,
		...styling.mixins.margin(0, 0, 20),
	},
	body: {
		fontSize: "2.4rem",
		lineHeight: "3.2rem",
		fontWeight: 700,
		color: styling.colors.textTertiary,
		...styling.mixins.margin(0, 0, 40),
	},
	byLine: {
		fontSize: "1.6rem",
		lineHeight: "2rem",
		color: styling.colors.linkDefault,
		...styling.mixins.margin(0, 0, 20),
	},
	fullName: {
		fontWeight: 700,
	},
	resortImage: {
		height: 60,
		width: 60,
	},
};
