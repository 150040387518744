import styling from "Services/styling";

export default {
	summaryFeatures: {
		display: "none",
		[styling.sizes.breakpoints.medium]: {
			display: "flex",
			flexWrap: "wrap",
			flexDirection: "row",
			alignItems: "unset",
			justifyContent: "center",
		},
	},
};
