import React from "react";
import { string } from "prop-types";

import { KeyFeaturePropTypes } from "~/types";
import FeatureIcon from "Components/FeatureIcon";
import Styles from "./styles.css";

const KeyFeatureSummary = (props) => {
	const { icon, featureType, strap } = props;
	return (
		<div css={Styles.wrapper}>
			<div css={Styles.icon}>
				<FeatureIcon icon={icon} />
			</div>
			<div css={Styles.type}>{featureType}</div>
			<div>{strap}</div>
		</div>
	);
};

KeyFeatureSummary.propTypes = {
	icon: string,
	...KeyFeaturePropTypes,
};

export default KeyFeatureSummary;
